import React from 'react';

const logError = console.log;

export default class ErrorBoundary extends React.Component<{ layer: string; }, { hasError: boolean; }> {
  layer = 'root';

  constructor(props: { layer: string; }) {
    super(props);
    this.state = { hasError: false };
    this.layer = props.layer;
  }

  static getDerivedStateFromError(_error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // You can also log the error to an error reporting service
    logError(`Error[${this.layer}]: `, error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}
