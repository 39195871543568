import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/styles/tailwind.css';

import ErrorBoundary from 'components/ErrorBoundary';

// layouts
const Admin = lazy(() => import('layouts/Admin'));
const Auth = lazy(() => import('layouts/Auth'));

// views without layouts
// const Landing = lazy(() => import('screens/Landing'));
// const Profile = lazy(() => import('screens/Profile'));
// const Index = lazy(() => import('screens/index'));

export default function App() {
  return (
    <Router>
      <ErrorBoundary layer="root">
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            {/* add routes with layouts */}
            {/* <Route path="/" exact component={Index} /> */}
            <Route path="/auth" component={Auth} />
            <Route path="/" component={Admin} />
            {/* add routes without layouts */}
            {/* <Route path="/landing" exact component={Landing} /> */}
            {/* <Route path="/profile" exact component={Profile} /> */}
            {/* add redirect for first page */}
            <Redirect from="*" to="/" />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </Router>
  );
}
